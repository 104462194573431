import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import 'wicg-inert';
import ReactDOM from 'react-dom';
import { phoneSize } from 'global-styles';
import Close from 'images/Dialog/close.svg';
import SVG from 'react-inlinesvg';

import frTranslationMessages from 'translations/fr.json';
import FppmLogo from 'images/MigrationModal/fppmLogo.png';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

const Overlay = styled.div`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 32, 69, 0.6);
`;

const BodyContainer = styled.div`
  display: block;
  position: absolute;
  margin-left: 25px;
  margin-right: 25px;
  max-width: 648px;
  width: 100%;
  z-index: 9001;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

const Top = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 80px;
  padding-top: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: center;

  .img {
    width: 384px;
  }
`;

const Body = styled.div`
  background-color: #ffffff;
  max-height: 90vh;
  overflow-y: auto;
  &.bodyOnly {
    border-radius: 12px;
    padding: 0;
    margin: 0;
    @media (max-width: ${phoneSize}) {
      max-height: 80vh;
    }
  }
`;

const Bottom = styled.div`
  background-color: #ffffff;
  height: 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const Center = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;

  h2 {
    margin-bottom: 20px;
  }
`;

const Attention = styled.div`
  color: #09709c;
  text-align: center;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const Title = styled.div`
  color: #303333;
  text-align: center;
  font-family: 'Gilroy';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Text = styled.div`
  color: #545959;
  text-align: center;
  font-family: 'Gilroy';
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.25px;
`;

const StyledButton = styled.button`
  width: 296px;
  height: 48px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ outline }) => (outline ? '#d0dbdb' : 'none')};
  background-color: ${({ outline }) => (outline ? 'none' : '#00544E')};
  color: ${({ outline }) => (outline ? '#545959' : '#D9F0FA')};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  span {
    font-size: 15px;
    width: 100%;
  }

  &:hover {
    background-color: ${({ outline }) => (outline ? '#24262608' : '#004645')};
  }
`;

const StyledButtonLabel = styled.span`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: 8px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Button = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClick, disable, label, style, type, input } = props;

  return (
    <>
      <StyledButton
        type={type || 'button'}
        onClick={onClick}
        style={style}
        disable={disable}
        {...input}
        {...props}
      >
        <StyledButtonLabel>{label}</StyledButtonLabel>
      </StyledButton>
    </>
  );
};

class FppmMigrationDialog extends React.PureComponent {
  componentDidMount() {
    if (this.props.open) {
      this.hideNavBar();
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (prevProps.open !== open) {
      this.hideNavBar();
      if (open) {
        document.getElementById('app').inert = true;
      } else {
        document.getElementById('app').inert = false;
      }
    }
  }

  hideNavBar = () => {
    const { open } = this.props;
    const navBar = document.getElementById('navBar');

    if (navBar) {
      navBar.classList.toggle('move-down', open);
    }
    document.body.classList.toggle('no-scroll', open);
  };

  render() {
    const { open, onClose } = this.props;

    if (!open) return <></>;

    return ReactDOM.createPortal(
      <Container>
        <IntlProvider locale="fr" messages={frTranslationMessages}>
          <Overlay />
          <BodyContainer role="dialog" aria-modal="true" maxWidth="600px">
            <Top>
              <img src={FppmLogo} alt="FPPM logo" />
            </Top>
            <Body>
              <CloseButton onClick={onClose}>
                <SVG src={Close} />
              </CloseButton>
              <Center>
                <Attention style={{ marginTop: 20 }}>
                  <FormattedMessage defaultMessage="Attention" />
                </Attention>
                <Title>
                  <FormattedMessage defaultMessage="Important announcement: Improvements to the FPPM health portal" />
                </Title>
                <Text style={{ marginTop: 8 }}>
                  <FormattedMessage defaultMessage="We are pleased to announce the upcoming enhancement of the FPPM insurance claims portal.  This enhancement will provide you with a more intuitive, modern and user-friendly experience." />
                  <br />
                  <br />
                  <FormattedMessage defaultMessage="With this update, all mental health services currently available through Inkblot will be consolidated on this new platform." />
                  <br />
                  <br />
                  <FormattedMessage defaultMessage="If you have any questions or difficulties, our team will be happy to assist you." />
                </Text>
              </Center>
              <ButtonContainer style={{ marginTop: 44 }}>
                <Button
                  outline
                  label={
                    <FormattedMessage defaultMessage="Continue with dependent registration" />
                  }
                  onClick={() =>
                    window.location.assign('http://app.fppm.qc.ca/dependant')
                  }
                />
                <Button
                  label={
                    <FormattedMessage defaultMessage="Continue with plan member registration" />
                  }
                  onClick={() =>
                    window.location.assign('http://assurances.fppm.qc.ca/')
                  }
                />
              </ButtonContainer>
            </Body>
            <Bottom />
          </BodyContainer>
        </IntlProvider>
      </Container>,
      document.body,
    );
  }
}

FppmMigrationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FppmMigrationDialog;
